import React from 'react'

type Props = {
}

export function Footer(props: Props) {
  return  <footer className="mt-16 py-8 border-t text-xs container-w text-gray-500 flex justify-between">
    <span className="ml-4">© 2021 CodeStory SAS – Paris, FRANCE </span>
    <span className="mr-4"><a href="mailto:hello@codestoryapp.com">contact</a></span>
  </footer>

}
