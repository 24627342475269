import classNames from 'classnames'
import { Link } from 'gatsby'
import logo from 'images/logo.svg'
import React from 'react'

type Props = {
  fixed?: boolean
}

export function Header(props: Props) {
  const { fixed } = props
  const topSections = [
    { title: 'documentation', slug: '/documentation' },
    // { title: 'roadmap', slug: '/roadmap' },
    { title: 'blog', slug: '/blog' },
    { title: 'contact', slug: '/contact' },
  ]
  return <header className="">
    <div className={classNames({ fixed },
      'left-0 right-0 container-w flex justify-between bg-white bg-opacity-95 z-10 px-8 pt-2',
      'text-base sm:text-2xl')}
    >
      <Link to="/"><img src={logo} style={{ height: '1.5em' }} /></Link>
      <span className="flex ml-2">
        {topSections.map(section => <Link to={section.slug}
                                          key={section.slug}
                                          partiallyActive
                                          className="mx-2"
                                          activeClassName="text-red-400"
        >{section.title}</Link>)}
      </span>
    </div>
  </header>
}
