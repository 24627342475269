import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'

export default () => {
  const query = useStaticQuery(graphql`
      query ContactPage {
          sitePage {
              path
          }
          mdx {
              frontmatter {
                  title
              }
          }
          convertkit: allFile(filter: {name: {eq: "convertkit"}}) { # #yVDuR#
              nodes {
                  childPlainText {
                      content
                  }
              }
          }
      }
  `)
  const convertKitForm = query.convertkit.nodes[0].childPlainText.content
  return <div>
    <Helmet>
      {/* #BrdlD# */}
      <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
    </Helmet>
    <Header fixed />
    <main
      className="py-24 container px-5 lg:mx-auto max-w-3xl"
    >
      {/* #fhSVj# */}
      <div dangerouslySetInnerHTML={{ __html: convertKitForm }} />
    </main>
    <Footer />
  </div>
};
